import React, { FC } from 'react';
import { isPropertySignature } from 'typescript';
import './Button.scss';

interface ButtonProps {
  text: string;
}

const Button: FC<ButtonProps> = (props: ButtonProps) => (
  <div className="Button">
    <span>{props.text}</span>
  </div>
);

export default Button;
