import React, { FC } from 'react';
import Button from '../Button/Button';
import './Header.scss';

interface HeaderProps {}

const Header: FC<HeaderProps> = () => (
  <header className="header">
    <h1>Dan Parkinson</h1>
    <div className="button-area">
      <Button text="About"></Button>
      <Button text="Projects"></Button>
      <Button text="Contact"></Button>
    </div>
  </header>
);

export default Header;
